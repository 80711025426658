//
// rangeslider.scss
//

.noUi-target {
    background: $gray-100 !important;
    border-radius: 5px !important;
    border: 1px solid $gray-200 !important;
    box-shadow: none !important;
}

.noUi-horizontal {
    height: 10px !important;

    .noUi-handle {
        width: 24px !important;
        height: 20px !important;
        right: -12px !important;
        top: -6px !important;
        outline: none !important;
    }
}

.noUi-handle {
    border: 1px solid $gray-300 !important;
    background: $white !important;
    box-shadow: none !important;
}

.noUi-handle:before, .noUi-handle:after {
    height: 10px !important;
    width: 2px !important;
    background: $gray-400 !important;
    left: 9px !important;
    top: 4px !important;
}

.noUi-handle:after {
    left: 12px !important;
}

.noUi-connect {
    background: $primary !important;
}