/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/


/* body {
  background-color: #f0f0f0;
}

.App {
  flex-direction: column;
  justify-content: left;
  margin: 20px auto 0 auto;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: #ff7a0a;
}

.CategorySection {
  margin: 40px 0 0 0;
  color: #ff7a0a;
}

.Item-card {
  text-align: left;
  margin: 5px 0px;
} */

.dashboard ul li.list-group-item:hover {
  background-color: #eef2f7;
}

form button.btn-primary, form button.btn-danger {
  width: 120px;
}

form img#imageThumbnail {
  max-height: 64px;
  height: auto;
}

.btn-danger, .btn-danger:hover,.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  border-color: #D15347;
  background-color: #D15347;
  box-shadow: 0px 2px 6px 0px rgba(209,83,71, 0.5);
}

.alert-danger {
  border-color: #D15347;
  background-color: #D15347;
}

.badge-danger {
  background-color: #D15347;
}

.btn-light {
  background-color: white;
}

.btn-light.text-red {
  color: #D15347;
}

/* Hyper sets position: relative, site wide, which breaks assumptions in react-bootstrap. Set it back here. */

html {
  position: initial !important;
}
