.item-row--clickable {
  cursor: pointer;
  &:hover {
      background-color: rgba($indigo, 0.15);
  }
}

.item-row--deleted {
  border: 1px solid $red;
  opacity: 0.6;
}

.card .list-group-item.item-row--reordering {
  border: 1px solid $indigo !important;
  border-style: dashed !important;
  border-radius: 8px !important;
  cursor: move;
}

.item-row--dragging {
  background-color: rgba($pink, 0.15);
}